'use strict';

import config from './config.js';

export default class ListTool {
  constructor({ data, api, config, readOnly, block }) {
    this.data = data;
    this.api = api;
    this.config = config;
    this.readOnly = readOnly;
    this.block = block;
  }

  static get toolbox() {
    return {
      title: 'List Item',
      icon: `<span style="font-weight: bold;">L</span>`
    };
  }

  render() {
    if (window.renderingInProgress) {
      window.currentlyRenderedBlockId++;
    }
    const div = document.createElement('div');
    div.contentEditable = true;
    div.classList.add('list-element');
    div.addEventListener('paste', (evt) => customOnPaste(evt, this.api));
    if (this.data?.text) {
      const innerHTML = getHighlighedSyntax(this.data.text, this.api, true, getBlockIdxById(this.block.id));
      if (window.renderingInProgress) {
        // get innerText
        const div = document.createElement('div');
        div.innerHTML = innerHTML;
        window.renderedBlocksText.push(div.innerText);
      }
      div.innerHTML = innerHTML;
    }

    div.onkeydown = (evt) => {
      if (evt.key === 'PageUp' || evt.key === 'PageDown') {
        const focusedBlockIdx = this.api.blocks.getCurrentBlockIndex();
        const blockCount = this.api.blocks.getBlocksCount();
        if (focusedBlockIdx > -1) {
          let blockToFocus = focusedBlockIdx + config.pageUpDownMoveFactor * (evt.key === 'PageDown' ? 1 : -1);
          if (blockToFocus < 0) {
            blockToFocus = 0;
          } else if (blockToFocus > blockCount - 1) {
            blockToFocus = blockCount - 1;
          }
          if (blockToFocus !== focusedBlockIdx) {
            const caretSet = this.api.caret.setToBlock(blockToFocus, 'start');
            if (!caretSet) {
              window.onCaretError(blockToFocus, 'start');
            }
          }
        }
        return;
      } else if (evt.key === 'Tab' && !window.activeTagsList) {
        // Disable default Tab key handling by editor.js
        evt.stopPropagation();
        evt.preventDefault();

        const cursorPos = window.getCursorPos();

        // Insert tab character manually
        insertStringAtCurrentActiveElement('\t', cursorPos, this.api);

        return false;
      } else if (window.activeTagsList) {
        // Tag list is open and we need to handle it
        if (evt.code === 'ArrowUp' || evt.code === 'ArrowDown') {
          console.info('handle arrow');
          evt.preventDefault();
          evt.stopImmediatePropagation();
          evt.stopPropagation();
          const currentActiveListItem = document.querySelector('.tags-picker li.active');
          if (!currentActiveListItem) {
            document.querySelector('.tags-picker li')?.classList.add('active');
          } else {
            if (evt.code === 'ArrowUp') {
              if (currentActiveListItem.previousElementSibling) {
                currentActiveListItem.classList.remove('active');
                currentActiveListItem.previousElementSibling?.classList.add('active');
              }
            } else {
              if (currentActiveListItem.nextElementSibling) {
                currentActiveListItem.classList.remove('active');
                currentActiveListItem.nextElementSibling?.classList.add('active');
              }
            }
          }
          return false;
        } else if (evt.key === 'Enter' || evt.key === 'Tab') {
          const tagSelectedInPicker = document.querySelector('.tags-picker li.active');
          if (tagSelectedInPicker) {
            const editedNode = window.getSelection().anchorNode;
            editedNode.textContent = tagSelectedInPicker.innerText + ' ';
            setCursorPosToNodeEnd(editedNode);

            window.setTimeout(() => {
              window.hideTagList();
            }, 0);

            evt.preventDefault();
            evt.stopImmediatePropagation();
            evt.stopPropagation();
            return false;
          } else {
            window.hideTagList();
          }
        }
      } else if (evt.key === 'Enter') {
        const currentElement = document.activeElement.innerText;
        const onlyWhitespace = currentElement.trim().length === 0;
        const cursorPos = window.getCursorPos();
        const nodeLength = window.getNodeLength(document.activeElement);
        // Are we not at the end of the line?
        if (cursorPos < nodeLength) {
          // Set cursor to the end of the line and stop processing the event
          window.setCursorPos(nodeLength);

          evt.stopPropagation();
          evt.stopImmediatePropagation();
          evt.preventDefault();
          return false;
        }

        if (onlyWhitespace) {
          // Remove indentation, as requested by Tomasz
          document.activeElement.innerHTML = '';
          evt.stopPropagation();
          return false;
        } else if (currentElement.startsWith('\t')) {
          // Handle subtasks
          const tabsCount = currentElement.match(window.tabsRegex)[0].length;
          const currentActive = document.activeElement;
          const intervalId = window.setInterval(() => {
            // Wait for document.activeElement to change and insert the required amount of tabs
            // TODO - figure out a solution without looping
            if (document.activeElement === currentActive) {
              return;
            }
            window.clearInterval(intervalId);
            let indentationString = '';
            for (let i = 0; i < tabsCount; i++) {
              indentationString += '\t';
            }
            insertStringAtCurrentActiveElement(indentationString, cursorPos, this.api);
            window.setCursorPos(cursorPos + tabsCount);
          }, 1);
        }
      } else if ((evt.ctrlKey || evt.metaKey) && (evt.code === 'ArrowUp' || evt.code === 'ArrowDown')) {
        const movingUp = evt.code === 'ArrowUp';
        // Handle block swapping by Ctrl+UP, Ctrl+Down
        // Prevent processing the event, so that the editor.js logic won't run - otherwise, the cursor is set to the begging/end of the line
        evt.preventDefault();
        evt.stopImmediatePropagation();
        evt.stopPropagation();

        const focusedBlockIdx = this.api.blocks.getCurrentBlockIndex();
        const taskRange = window.getTaskRange(focusedBlockIdx, this.api);
        const blockCount = this.api.blocks.getBlocksCount();
        const cursorPos = window.getCursorPos();

        // Check edge cases
        if (movingUp && taskRange.startIdx === 0) {
          console.warn(`Cannot move task up - it's the first one`);
          return;
        } else if (!movingUp && taskRange.endIdx === blockCount) {
          console.warn(`Cannot move task down - it's the last one`);
          return;
        }

        if (movingUp) {
          // We need to get the range of previous task
          const previousTaskRange = window.getTaskRange(taskRange.startIdx - 1, this.api);
          moveTaskToPosition(taskRange, previousTaskRange.startIdx, this.api);
        } else {
          const nextTaskRange = window.getTaskRange(taskRange.endIdx + 1, this.api);
          if (nextTaskRange) {
            moveTaskToPosition(nextTaskRange, taskRange.startIdx, this.api);
          }
        }

        // Restore focus to the proper block and position
        const caretSet = this.api.caret.setToBlock(
          evt.code === 'ArrowUp' ? focusedBlockIdx - 1 : focusedBlockIdx + 1,
          'start'
        );
        if (!caretSet) {
          window.onCaretError(evt.code === 'ArrowUp' ? focusedBlockIdx - 1 : focusedBlockIdx + 1, 'start');
        }

        // We need custom function to set the cursor position, with a delay, because
        // editor.js uses a 20ms timeout under the hood in `setToBlock` method
        window.setTimeout(() => {
          window.setCursorPos(cursorPos);
        }, 20);

        return false;
      } else if (evt.code === 'ArrowUp' || evt.code === 'ArrowDown') {
        // Imitate Windows notepad when pressing Arrow Up/Down
        const focusedBlockIdx = this.api.blocks.getCurrentBlockIndex();
        const cursorPos = window.getCursorPos();
        if (evt.code === 'ArrowUp') {
          const caretSet = this.api.caret.setToBlock(focusedBlockIdx - 1, 'start');
          if (!caretSet) {
            window.onCaretError(focusedBlockIdx - 1, 'start');
          }
        } else {
          const caretSet = this.api.caret.setToBlock(focusedBlockIdx + 1, 'start');
          if (!caretSet) {
            window.onCaretError(focusedBlockIdx + 1, 'start');
          }
        }
        // We need custom function to set the cursor position, with a delay, because
        // editor.js uses a 20ms timeout under the hood in `setToBlock` method
        window.setTimeout(() => {
          window.setCursorPos(cursorPos);
        }, 20);

        evt.preventDefault();
      } else if (evt.key === '#') {
        const tags = getUniqueTags();
        if (tags.length > 0) {
          // TODO - make it work?
          // const rect = evt.target.getBoundingClientRect();
          // const ul = createTagPicker(tags, 999, rect.left);
          // document.body.appendChild(ul);
          // window.activeTagsList = ul;
        }
      }
    };

    new MutationObserver((ev) => {
      if (document.activeElement?.tagName === 'BODY') {
        return;
      }
      window.activeTagsList?.remove();
      window.activeTagsList = null;
      showSavingIndicator();
      // Save current cursor position
      const cursorPos = window.getCursorPos();
      if (document.activeElement) {
        window.setTimeout(() => {
          // Highlight syntax while editing
          if (document.activeElement.tagName !== 'BODY') {
            document.activeElement.innerHTML = getHighlighedSyntax(document.activeElement.innerHTML, this.api);

            // Restore previous cursor position
            window.setCursorPos(cursorPos);
          }
        }, 0);
      }

      const textData = String(ev[0].target.data).toLowerCase().trim();
      if (textData.match(window.tagRegex) && textData !== '#') {
        // Tag was modified - show suggestions
        const tags = getUniqueTags().filter((e) => e.toLowerCase().startsWith(textData));
        if (tags.includes(textData)) {
          tags.splice(tags.indexOf(textData), 1);
        }
        if (tags.length > 0) {
          const rect = ev[0].target.parentElement.getBoundingClientRect();
          const ul = createTagPicker(tags, rect.top + 8, rect.left);
          document.body.appendChild(ul);
          window.activeTagsList = ul;
        }
      }
      const matchesCompletedTaskRegex = completedTaskRegex.test(textData);
      const includesCompletedTaskTag = textData.includes(window.completedTaskTag);

      if (matchesCompletedTaskRegex || includesCompletedTaskTag) {
        if (window.completedTaskDetectionTimeout) {
          window.clearTimeout(window.completedTaskDetectionTimeout);
        }
        window.completedTaskDetectionTimeout = window.setTimeout(() => {
          const editedNode = window.getSelection().anchorNode;
          const editedNodeTextContent = editedNode.textContent;
          const updatedTextContent = editedNodeTextContent.replace(
            editedNodeTextContent.match(completedTaskRegex)?.[0],
            ''
          );
          if (updatedTextContent !== editedNodeTextContent) {
            editedNode.textContent = updatedTextContent;
          }
          // Insert #completed tag and the ball animation at the end of the current task - if not subtask or comment
          const _isBlockCommentOrSubtask = window.isBlockCommentOrSubtask(document.activeElement.innerText);
          const blockIdx = this.api.blocks.getCurrentBlockIndex();
          const blockId = this.api.blocks.getBlockByIndex(blockIdx).id;
          // Updating the block using api.blocks.update causes it to lose focus
          let updatedText = document.activeElement.innerHTML;
          if (!includesCompletedTaskTag) {
            updatedText += ' ' + window.completedTaskTag;
          }
          if (!_isBlockCommentOrSubtask) {
            updatedText += window.getBallGifCode();
          }
          this.api.blocks.update(blockId, {
            text: updatedText
          });
          if (!_isBlockCommentOrSubtask) {
            window.setTimeout(() => {
              // Remove the ball
              this.api.blocks.update(blockId, {
                text: getBlockElementById(blockId).innerHTML.replace(window.ballGifRegex, '')
              });
              // Move task to the bottom
              if (!document.activeElement.innerHTML.startsWith('\t')) {
                const taskRange = window.getTaskRange(blockIdx, this.api, true);
                console.log('trr', taskRange);

                let targetBlockPosition;
                const blockCount = this.api.blocks.getBlocksCount();
                let completedTaskFound = false;

                for (targetBlockPosition = 0; targetBlockPosition < blockCount; targetBlockPosition++) {
                  if (targetBlockPosition === blockIdx) {
                    continue;
                  }
                  // Get block from the DOM
                  const blockElem = window.getBlockElementById(this.api.blocks.getBlockByIndex(targetBlockPosition).id);
                  // Is it completed?
                  const isCompletedBlock = blockElem.innerText.includes(window.completedTaskTag);
                  if (isCompletedBlock && !window.isBlockCommentOrSubtask(blockElem.innerText)) {
                    completedTaskFound = true;
                    break;
                  }
                }

                if (targetBlockPosition >= blockCount) {
                  targetBlockPosition = blockCount - 1;
                }
                if (blockIdx < targetBlockPosition && completedTaskFound) {
                  targetBlockPosition--;
                  const taskRange2 = window.getTaskRange(targetBlockPosition, this.api);
                  targetBlockPosition = Math.max(0, taskRange2.endIdx + 1);
                }

                // Move the completed task to the bottom (at the top of the completed tasks list)
                console.error(targetBlockPosition, blockIdx);
                if (targetBlockPosition !== blockIdx) {
                  console.log(`moving the completed task from ${blockIdx} to ${targetBlockPosition}`);
                  moveTaskToPosition(taskRange, targetBlockPosition, this.api);
                }
              }
            }, config.completedTaskMoveDelay);
          }

          setCursorPosToNodeEnd(document.activeElement.childNodes[document.activeElement.childNodes.length - 1]);
        }, window.completedTaskDetectionDelay);

        hideTagList();
      }
    }).observe(div, {
      subtree: true,
      attributes: false,
      childList: false,
      characterData: true,
      characterDataOldValue: false
    });

    new MutationObserver((events) => {
      // This second mutation observer is only used to hide tags list, when # is removed
      if (events.length === 2) {
        window.activeTagsList?.remove();
        window.activeTagsList = null;
      }
    }).observe(div, {
      subtree: false,
      attributes: false,
      childList: true,
      characterData: false,
      characterDataOldValue: false
    });

    return div;
  }

  save(blockContent) {
    return {
      // Manual line break replacement required because of Chrome vs Firefox incosistency when returning innerHTML
      text: blockContent.innerHTML.replaceAll('<br>', '\n')
    };
  }
}

function customOnPaste(evt, api) {
  const activeBlockIdx = api.blocks.getCurrentBlockIndex();
  const specialCharsRegex = /^(\W|_)+/g;
  // Split pasted content into lines, remove special chars from the beginning of each line and trim the result
  const rows = evt.clipboardData
    .getData('text')
    .split('\n')
    .map((t) => t.replace(specialCharsRegex, '').trim())
    .filter((t) => t);

  if (rows.length > 0) {
    let lastBlockIdx = null;
    let cursorPos = null;
    let activeBlockId = null;
    if (activeBlockIdx > -1) {
      lastBlockIdx = activeBlockIdx;
      cursorPos = getCursorPos();
      activeBlockId = api.blocks.getBlockByIndex(activeBlockIdx).id;
    }

    for (const [idx, row] of rows.entries()) {
      if (idx === 0 && activeBlockIdx > -1) {
        // If there's an active block - update it, instead of creating a new one
        const existingText = getBlockElementById(activeBlockId).innerText;
        api.blocks.update(activeBlockId, {
          text: existingText.slice(0, cursorPos) + row + existingText.slice(cursorPos)
        });
      } else {
        api.blocks.insert(
          'customList',
          {
            text: row
          },
          undefined,
          undefined,
          true
        );
        lastBlockIdx++;
      }
    }

    if (rows.length > 1) {
      if (lastBlockIdx !== null) {
        // Focus last pasted block
        window.setTimeout(() => {
          const caretSet = api.caret.setToBlock(lastBlockIdx, 'end');
          if (!caretSet) {
            window.onCaretError(lastBlockIdx, 'end');
          }
        }, 0);
      }
    } else {
      // 1 row pasted - focus at the end of the paste
      window.setTimeout(() => {
        const caretSet = api.caret.setToBlock(activeBlockIdx, 'start');
        if (!caretSet) {
          window.onCaretError(activeBlockId, 'start');
        }
        window.setTimeout(() => {
          setCursorPos(cursorPos + rows[0].length);
        }, 20);
      }, 0);
    }
  }

  // Prevent handling the paste event by editor.js, because it's buggy
  evt.preventDefault();
  evt.stopImmediatePropagation();
  evt.stopPropagation();
  return false;
}

function getHighlighedSyntax(sInput, api, processChildren = true, _currentBlockIdx = null) {
  // Mark tags and dates
  // Strip previous tags first using a helper div
  const div = document.createElement('div');
  div.innerHTML = sInput;
  const stripped = api.sanitizer.clean(div.innerHTML, { br: true, img: true });
  let tags = [];
  // If comment or indented - find parent tag and inherit styles
  if (window.isBlockCommentOrSubtask(stripped)) {
    if (window.renderingInProgress) {
      for (let i = window.currentlyRenderedBlockId - 1; i >= 0; i--) {
        if (window.renderedBlocksText[i] && !window.isBlockCommentOrSubtask(window.renderedBlocksText[i])) {
          tags.push(...(window.renderedBlocksText[i].match(window.tagRegex) || []));
          break;
        }
      }
    } else {
      let currentBlockElement;
      let currentBlockIdx = _currentBlockIdx ?? api.blocks.getCurrentBlockIndex();
      if (currentBlockIdx === -1) {
        // Use activeElement to determine current block
        currentBlockElement = document.activeElement.parentElement.parentElement.previousElementSibling;
      } else {
        currentBlockElement = window.getBlockElementById(
          api.blocks.getBlockByIndex(currentBlockIdx).id
        ).previousElementSibling;
      }

      while (currentBlockElement) {
        if (!window.isBlockCommentOrSubtask(currentBlockElement.innerText)) {
          tags.push(...(currentBlockElement.innerText.match(window.tagRegex) || []));
          break;
        }
        currentBlockElement = currentBlockElement.previousElementSibling;
      }
    }
  } else if (!window.renderingInProgress && processChildren) {
    // Apply parent's tags to children and comments
    const taskRange = window.getTaskRange(api.blocks.getCurrentBlockIndex(), api);
    for (let i = taskRange.startIdx + 1; i <= taskRange.endIdx; i++) {
      const blockId = api.blocks.getBlockByIndex(i).id;
      window.setTimeout(() => {
        // setTimeout is required to avoid `Error: Incorrect index` from editor.js
        const blockElem = getBlockElementById(blockId);
        // Trigger block update - force re-render and add tags
        api.blocks.update(blockId, {
          text: blockElem.innerHTML
        });
      }, 0);
    }
  }
  if (!stripped.startsWith(window.defineBlockPrefixEncoded)) {
    tags.push(...(stripped.match(window.tagRegex) || []));
  }

  tags = tags.map((e) => `tag-${e.slice(1).toLowerCase()}`);
  const highlighted = stripped
    .replace(window.tagRegex, (match) => `<span class="tag">${match}</span>`)
    .replace(/\d{4}-\d{2}-\d{2}/g, `<span class="date">$&</span>`);
  return `<div class="${tags.join(' ')}">${highlighted}</div>`;
}

function insertStringAtCurrentActiveElement(stringToInsert, cursorPos, api) {
  // TODO - use the api instead of direct DOM manipulation
  if (!document.activeElement || document.activeElement.tagName === 'BODY') {
    console.warn('Cannot insert string at current position');
    return;
  }
  const innerText = document.activeElement.innerText;
  const currentBlockIndex = api.blocks.getCurrentBlockIndex();
  const activeBlockId = api.blocks.getBlockByIndex(currentBlockIndex).id;
  api.blocks.update(activeBlockId, {
    text: innerText.slice(0, cursorPos) + stringToInsert + innerText.slice(cursorPos)
  });
  // Restore lost focus
  window.setTimeout(() => {
    const caretSet = api.caret.setToBlock(currentBlockIndex, 'start');
    if (!caretSet) {
      window.onCaretError(currentBlockIndex, 'start');
    }
    window.setTimeout(() => {
      setCursorPos(cursorPos + 1);
    }, 20);
  }, 0);
}

function createTagPicker(tags, top, left) {
  // Create and return a list of tags
  // top, left - numbers, specify position of the picker
  if (!Array.isArray(tags)) {
    console.error('Cannot create a tag picker for non-array value for tags', tags);
  }
  const ul = document.createElement('ul');
  ul.classList.add('tags-picker');
  for (const [idx, tag] of tags.entries()) {
    const li = document.createElement('li');
    li.innerText = tag;
    if (idx === 0) {
      li.classList.add('active'); // Preselect first item in the tags list
    }
    ul.appendChild(li);
    li.addEventListener('click', function (evt) {
      // todo - handle click evt?
      evt.stopPropagation();
    });
  }
  ul.style.top = `${top}px`;
  ul.style.left = `${left}px`;

  return ul;
}

function getUniqueTags() {
  const editorContent = document.getElementById('editorjs').innerText;
  return Array.from(new Set(editorContent.match(window.tagRegex)));
}

function moveTaskToPosition(taskRange, startPos, api) {
  const blockCount = api.blocks.getBlocksCount();
  // Given a task range, move it, so that it starts at the specified startPos
  if (taskRange.startIdx === startPos) {
    return;
  }

  const blockMoveRowsCount = Math.abs(startPos - taskRange.startIdx);

  const movingUp = startPos < taskRange.startIdx;

  if (movingUp) {
    // Loop order depends on the direction of sorting
    for (let i = taskRange.startIdx; i <= taskRange.endIdx; i++) {
      const targetBlockPosition = Math.max(0, i - blockMoveRowsCount);
      console.warn('moving', i, 'to', targetBlockPosition);
      if (targetBlockPosition !== i) {
        api.blocks.move(targetBlockPosition, i);
      }
    }
  } else {
    const targetBlockPosition = Math.min(taskRange.endIdx + blockMoveRowsCount, blockCount - 1);
    for (let i = taskRange.endIdx; i >= taskRange.startIdx; i--) {
      if (targetBlockPosition !== taskRange.startIdx) {
        api.blocks.move(targetBlockPosition, taskRange.startIdx);
      }
    }
  }
}

function getBlockIdxById(id) {
  // Use DOM to determine block index based on the id
  let blockElem = window.getBlockElementById(id);
  let idx = -1;
  while (blockElem) {
    idx++;
    blockElem = blockElem.previousElementSibling;
  }

  return idx;
}
